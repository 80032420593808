import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';
import Subscribe from '../components/subscribe/Subscribe';
import config from '../website-config';

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }
`;

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>Triggers for evolution</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header css={[outer, SiteHeader]}>
        <div css={inner}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader>
            <PostFullTitle>Triggers for evolution</PostFullTitle>
          </PostFullHeader>
          <PostFullContent className="post-full-content">
            <div className="post-content">
              <p>Are you experiencing some form of growing pain or &quot;awkward interaction&quot; that doesn&apos;t feel quite right. There are often situations or scenarios that are common across many organizations that might indicate the need for teams to evolve. Take a look at some of the scenarios below and see if they resonate with you.</p>
              <h2>Common awkward interctions</h2>
              <p>We use the term awkward interaction to refer to situations that might indicate the need for team structures to evolve.</p>
              <h3>We&apos;re getting slower at delivering value</h3>
              <p>One of the biggest indicators that there is a mis-alignment of team and service boundaries is the feeling that work just seems to be taking longer than it used to.</p>
              <p>If this is the case, take a look at how your teams are currently organized, how many dependencies are there between teams? How many times is work &quot;handed off&quot; between teams? Teams that need to hand over work to another team or need to wait for another team to complete their work will deliver value far slower than teams that have complete end to end ownership of a value stream.</p>
              <h3>Too many people in a single meeting</h3>
              <p>As organizations grow there is often a need for people to &quot;stay informed&quot; about what is happening within the organization so they feel that they need to attend lots of meetings. When you begin to explore the main driver behind this need, it is often because the boundaries of ownership are unclear and there is a lack of guardrails or guidance about when people within the team should or should not be involved. To keep high trust within teams we should aim to keep the team size between 5-9 people. If you are observing team sizes that are larger than this it is very likely a signal that there is a need to identify new ownership boundaries. </p>
              <h3>Handovers between project teams - synchronization</h3>
              <p>If you have temporary project based teams that are being set up to deliver work but you find that work needs to be synchronized between them then this would be considered an awkward interaction. If you are currently working in this way then we would generally suggest that you look to move from project-oriented work to work driven by long-lived teams. Most organizations will have projects where multiple teams need to be involved, however, striving to have those teams owning and evolving particular scopes in the org is very important - that will also define &quot;who needs to be involved a given project&quot;</p>
              <h3>Functional silos</h3>
              <p>You may find that your organization has functional siloes and work is being handed off between each of the teams, for example a development team might handover work to a test team who then hands over work to a operations team. If we are experiencing this then we would typically suggest exploring ways to move towards teams that have each of those capabilities within them - what would it take to enable cross-functional teams that have end to end responsibility for the delivery of value to the customer? Get to the point where the team can build, test and run the systems they own. This may require the use of Enabling teams to help upskill.</p>
              <h3>Ongoing collaboration</h3>
              <p>You may find that some teams within your organization need to collaborate on an ongoing basis. Every time a change happens in one team, the other team also needs to make changes. This is a signal that the boundaries between the teams are potentially mis-placed according to the flow of value within your orgnization. You should probably explore ways to re-align the boundaries to achieve a better flow of value.</p>
              <h2>What awkward interactions have you noticed?</h2>
              <p>The common scenarios listed above are not exhaustive. We&apos;re sure there are many other situations that you are probably experiencing. We&apos;d love to hear about them and help you understand how to eliminate the bottlenecks that they cause to help improve the flow of value within your organization.</p>
            </div>
          </PostFullContent>
        </article>
        {/* The big email subscribe modal content */}
        {config.showSubscribe && <Subscribe title="What have we missed?" />}
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
